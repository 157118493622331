import React, { Component } from 'react';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import WelcomeForm from './WelcomeForm';

import { Navigate } from "react-router-dom";

const INITIAL_STATE = {
  navigateUrl : null,
};

class SelectListingType extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  renderSelectListingType = (icon, itemTypeName, itemTypeDescription, url) => {
    return <div className="selectListingType">
      <div className="selectListingTypeIconContainer" onClick={ () => this.setState({ "navigateUrl": url })}>
        <img className="selectListingTypeIcon" src={icon}/>
      </div>
      <div className="selectListingTypeName">{itemTypeName}</div>
      <div className="selectListingTypeDescription">{itemTypeDescription}</div>
    </div>
  }


  renderSelectListingTypes = () => {
    return <div>
      <div className="selectListingTypeHeader">What would you like to list?</div>
      {this.renderSelectListingType("/star.png", "Item", "List any new or used item for sale", "/create-item-listing")}
      {this.renderSelectListingType("/home.png", "Property", "List a property for rent or sale", "/create-property-listing")}
      {this.renderSelectListingType("/vehicle.png", "Vehicle", "List a new or used vehicle for sale", "/create-vehicle-listing")}
    </div>
  }

  render = () => {
    var firebase = this.props.firebase;
    var userLoaded = firebase.initialised;
    var user = this.props.firebase.user;

    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl}  replace={true} />)

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader back="/" firebase={firebase}/>
          <AppSidebar tab="Reports" firebase={firebase}/>
          <div className='App-content'>
            <div className='App-content-column'>
            {
              !userLoaded 
                ?
                <div>
                  
                </div>
                :  
              user
                ? 
                <div>
                    { this.renderSelectListingTypes() }
                   </div>
                : 
                <WelcomeForm/>
            }
           </div>
          </div>

          <AppFooter tab="Reports"/>
        </div>
      </div>
    )
  }
}

export default SelectListingType; 

