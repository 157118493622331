import React, { Component } from 'react';

import { Navigate } from "react-router-dom";

import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import Button from './Button'
import LoadingSpinner from './LoadingSpinner';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  navigateUrl : null,
  loggingIn: false
};

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    event.preventDefault();

    this.setState({ "loggingIn": true} )

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({"loggingIn" : false})

        this.setState({ "navigateUrl": "/" })
      })
      .catch(error => {
        this.setState({"loggingIn": false})
        this.setState({ error });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  resetMyPassword = () => {
    this.setState({ "navigateUrl": "/reset-my-password" })
  }

  render = () => {
    var firebase = this.props.firebase;

    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl}  replace={true} />)

    const {
      email,
      password,
      error,
    } = this.state;

    const isInvalid =
      password === '' ||
      email === '';
    
    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader back="/" title="Login" firebase={firebase} />
          <AppSidebar tab="Dashboard" firebase={firebase}/>
   
          <div className='App-content'>
            <div className='App-content-column-narrow'>

              { this.state.loggingIn ? 
              <LoadingSpinner/>
              :
              <div>
                <p>
                <input
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  type="text"
                  placeholder="Email"
                />

                <input
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  type="password"
                  placeholder="Password"
                />
                </p>

                <Button action="Login" size='medium' type='primary' disabled={isInvalid} onClick={this.onSubmit} action="Login"/>

                {error && <p>{error.message}</p>}
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;

