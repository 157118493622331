import React, { Component } from 'react';
import { doc, getDoc, setDoc } from "firebase/firestore"; 
import { Navigate, useParams } from "react-router-dom";

import short from 'short-uuid';
import Select from "react-select";

import AddressSelector from './AddressSelector';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import Button from './Button';
import ImageUploader from './ImageUploader';
import LoadingSpinner from './LoadingSpinner';
import MessagePopup from './MessagePopup';
import PhotosSummary from './PhotosSummary';
import TextareaAutosize  from 'react-textarea-autosize';
import WelcomeForm from './WelcomeForm'

const withRouter = WrappedComponent => props => {
  const params = useParams();
  // etc... other react-router-dom v6 hooks

  return (
    <WrappedComponent
      {...props}
      params={params}
      // etc...
    />
  );
};


const INITIAL_STATE = {
  createVehicle: true,
  dataLoaded: false,
  loadingListing: false,

  id : short().new(),

  make : "",
  model : "",
  body: "Sedan",
  transmission: "Automatic",
  engine: "",
  year: "",
  odometer: "0",

  price : 0,
  description : "",
  images : [],
  
  suburb : null,
  state : null,
  latitude : null,
  longitude : null,

  showAddressSelector: false,
  showImageUploader: false,

  showPopupMessage: false,
  popupTitle: "",
  popupMessage: "",
  popupOnClose: null
};

class EditVehicleListing extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

  }

  componentDidMount = () => {
    if (this.props.params && this.props.params.id) {
      this.setState({ createVehicle: false, id: this.props.params.id })
      this.loadListing(this.props.params.id)
    }
  }

  loadListing = (id) => {
    this.setState({ loadingListing: true })

    getDoc(doc(this.props.firebase.firestore, "vehicles", id)).then((doc) => {
      if (doc.exists) {
        var vehicle = doc.data()

        this.setState({ 
          dataLoaded: true, 
          loadingListing: false, 

          make : vehicle.make,
          model : vehicle.model,
          body: vehicle.body,
          transmission: vehicle.transmission,
          engine: vehicle.engine,
          year: vehicle.year.toString(),
          odometer: vehicle.odometer.toString(),

          price : vehicle.price.toString(),
          description : vehicle.description,
          images : vehicle.images,
          suburb : vehicle.suburb,
          state : vehicle.state,
          latitude : vehicle.latitude,
          longitude : vehicle.longitude,
        })
      }
      else {
        alert("Could not find listing")
        this.redirectToHome()
      }
    })
  }

  renderSuburbSelector = () => {
    return <div>
      <div className='formFieldName'>Location</div>
      <div onClick={ () => { this.showAddressSelector() } } className='suburbSelectorBox'>{this.getSuburbText()}</div>
    </div>
  }

  showAddressSelector = () => {
    this.setState({ showAddressSelector: true })
  }

  closeAddressSelector = () => {
    this.setState({ showAddressSelector: false })
  }

  getSuburbText = () => {
    if (!this.state.suburb || !this.state.state || !this.state.latitude || !this.state.longitude) {
        return "🔍 Select your suburb"
    }
    else {
        return this.state.suburb + ", " + this.state.state
    }
  }

  addImage = (imageUrl) => {
    let images = [...this.state.images]
    images.push(imageUrl)

    this.setState({ images: images })
  }

  deleteImage = (index) => {
    if (index < this.state.images.length) {
      let images = this.state.images
      images.splice(index, 1)
      this.setState({ images: images })
    }
  }

  showPopupMessage = (title, message, onClose) => {
    this.setState({ showPopupMessage: true, popupTitle: title, popupMessage: message, popupOnClose: onClose })
  }

  closePopupMessage = () => {
    this.setState({ showPopupMessage: false })
  }

  showImageUploader = () => {
    this.setState({ showImageUploader: true })
  }

  closeImageUploader = () => {
    this.setState({ showImageUploader: false })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addressSelected = (address, suburb, state, latitude, longitude) => {
    this.setState({ suburb: suburb, state: state,  latitude: latitude, longitude: longitude, showAddressSelector: false })
  }

  isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  validateListing = () => {
    if (!this.state.suburb || !this.state.state || !this.state.latitude || !this.state.longitude) {
      this.showPopupMessage("Listing validation", "Please select vehicle location", this.closePopupMessage)
      return false
    }

    if (this.state.make.length < 3) {
      this.showPopupMessage("Listing validation", "Please enter a make", this.closePopupMessage)
      return false
    }

    if (this.state.model.length < 10) {
      this.showPopupMessage("Listing validation", "Please enter a model", this.closePopupMessage)
      return false
    }

    if (this.state.engine.length < 3) {
      this.showPopupMessage("Listing validation", "Please enter an engine description", this.closePopupMessage)
      return false
    }

    if (!this.isNumeric(this.state.year) || parseInt(this.state.year) < 1920 || parseInt(this.state.year) > 2020) {
      this.showPopupMessage("Listing validation", "Please enter a valid year", this.closePopupMessage)
      return false
    }

    if (!this.isNumeric(this.state.odometer) || parseInt(this.state.odometer) < 0 || parseInt(this.state.odometer) > 2000000) {
      this.showPopupMessage("Listing validation", "Please enter a valid odometer", this.closePopupMessage)
      return false
    }
    
    if (!this.isNumeric(this.state.price) || parseInt(this.state.price) <= 0) {
        this.showPopupMessage("Listing validation", "Please enter a valid price", this.closePopupMessage)
        return false
    }
    
    if (this.state.images.length === 0) {
        this.showPopupMessage("Listing validation", "Please upload some photos", this.closePopupMessage)
        return false
    }
    
    if (this.state.description.length < 20) {
        this.showPopupMessage("Listing validation", "Please enter a detailed description", this.closePopupMessage)
        return false
    }

    return true
  }

  redirectToHome = () => {
    this.setState({ navigateUrl: "/"})
  }

  storeListing = (status, callback) => {
    setDoc(doc(this.props.firebase.firestore, "vehicles", this.state.id), 
    {
      make : this.state.make,
      model : this.state.model,
      body: this.state.body,
      transmission: this.state.transmission,
      engine: this.state.engine,
      year: parseInt(this.state.year),
      odometer: parseInt(this.state.odometer),

      description: this.state.description,
      images: this.state.images,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      price: parseInt(this.state.price),
      state: this.state.state,
      status: status,
      suburb: this.state.suburb,
      vendorId: this.props.firebase.auth.currentUser.uid,
      vendorName: this.props.firebase.auth.currentUser.displayName
    })
    .then(callback)
  }

  postListingClicked = () => {
    if (!this.validateListing())
      return

    this.storeListing("Active", () => {
        if (this.state.createVehicle) 
           this.showPopupMessage("Vehicle listing", "Your listing has been posted successfully", this.redirectToHome)
        else 
           this.showPopupMessage("Vehicle listing", "Your listing has been updated successfully", this.redirectToHome)
    })
  }

  endListingClicked = () => {
    if (!this.validateListing())
      return

    this.storeListing("Ended", () => {
         this.showPopupMessage("Vehicle listing posted", "Your listing has been ended", this.redirectToHome)
    })
  }

  
  bodyChanged = (option) => {
    this.setState({ body: option.value })
  }

  renderBodySelector = () => {
    var options = [
      { value: "Convertible", label: "Convertible" },
      { value: "Coupe", label: "Coupe" },
      { value: "Hatch", label: "Hatch" },
      { value: "Sedan", label: "Sedan" },
      { value: "SUV", label: "SUV" },
      { value: "Ute", label: "Ute" },
      { value: "Van", label: "Van" },
      { value: "Wagon", label: "Wagon" }
    ]
    var defaultValue = options.filter(option => option.value === this.state.body)
    return <Select name="body" defaultValue={defaultValue} options={options} onChange = {this.bodyChanged}/>
  }

  transmissionChanged = (option) => {
    this.setState({ transmission: option.value })
  }

  renderTransmissionSelector = () => {
    var options = [
      { value: "Automatic", label: "Automatic" },
      { value: "Manual", label: "Manual" }
    ]
    var defaultValue = options.filter(option => option.value === this.state.transmission)
    return <Select name="transmission" defaultValue={defaultValue} options={options} onChange = {this.transmissionChanged}/>
  }

  renderCreateVehicleListingForm = () => {

    if (this.state.loadingListing) {
      return <LoadingSpinner/>
    }

    return <div>
        {this.renderSuburbSelector()} 

        <div className='formFieldName'>Make</div>
        <div className='formFieldInput'>      
          <input name="make" value={this.state.make} onChange={this.onChange} type="text" placeholder="Make"/>
        </div>

        <div className='formFieldName'>Model</div>
        <div className='formFieldInput'>      
          <input name="model" value={this.state.model} onChange={this.onChange} type="text" placeholder="Model"/>
        </div>

        <div className='formFieldName'>Body</div>
        <div className='formFieldInput'>{this.renderBodySelector()}</div>

        <div className='formFieldName'>Transmission</div>
        <div className='formFieldInput'>{this.renderTransmissionSelector()}</div>

        <div className='formFieldName'>Engine</div>
        <div className='formFieldInput'>      
          <input name="engine" value={this.state.engine} onChange={this.onChange} type="text" placeholder="Engine"/>
        </div>
  
        <div className='formFieldName'>Year</div>
        <div className='formFieldInput'>      
          <input name="year" value={this.state.year} onChange={this.onChange} type="text" placeholder="2023"/>
        </div>

        <div className='formFieldName'>Odometer (km)</div>
        <div className='formFieldInput'>      
          <input name="odometer" value={this.state.odometer} onChange={this.onChange} type="text" placeholder="Odometer"/>
        </div>

        <div className='formFieldName'>Price ($)</div>
        <div className='formFieldInput'>      
          <input name="price" value={this.state.price} onChange={this.onChange} type="text" placeholder="$0"/>
        </div>

        <div className='formFieldName'>Photos</div>
        <div className='formFieldInput'>      
          <PhotosSummary photos={this.state.images} onClick={() => this.showImageUploader()} />
        </div>

        <div className='formFieldName'>Description</div>
        <div className='formFieldInput'>      
          <TextareaAutosize minRows="6" cols="60" name='description' onChange={this.onChange}  value={this.state.description}/>
        </div>

        <br/>
        <Button size='medium' type='primary' action={(this.state.createVehicle) ? 'Post listing' : 'Updating listing'} onClick={ this.postListingClicked }/>
        { !this.state.createVehicle ?
          <div>
             <br/>
              <Button size='medium' type='secondary' action={'End listing'} onClick={ this.endListingClicked }/>
        </div>
        :
        <div/>
        }
      </div>
  }


  render = () => {
    var firebase = this.props.firebase;
    var userLoaded = firebase.initialised;
    var user = this.props.firebase.user

    var title = (this.state.createVehicle) ? "Create Vehicle Listing" : "Update Vehicle Listing"
    var back = (this.state.createVehicle) ? "/select-listing-type" : "/"

    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl}  replace={true} />)

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader back={back} title={title} firebase={firebase} />
          <AppSidebar tab="Dashboard" firebase={firebase}/>
          <div className='App-content'>
            <div className='App-content-column-narrow'>
            {
              !userLoaded 
                ?
                <div/>
                :
              user
                ? 
                this.renderCreateVehicleListingForm()
                : 
                <WelcomeForm/>
            }
           </div>
          </div>

            { this.state.showAddressSelector ?
                  <AddressSelector addressType='suburb' addressSelected={this.addressSelected} onClose={ () => this.closeAddressSelector() } />
                  :
                  <div/>
            }

           { this.state.showImageUploader ? 
              <ImageUploader firebase={this.props.firebase} images={this.state.images} imageAdded={this.addImage} imageDelete={this.deleteImage} onClose={() => this.closeImageUploader()}/>
              :
              <div/>
            }

            { this.state.showPopupMessage ?
              <MessagePopup title={this.state.popupTitle} message={this.state.popupMessage} onClose={this.state.popupOnClose}/>
              :
              <div/>
            }
          <AppFooter tab="My Listings"/>
        </div>
      </div>
    )
  }
}

export default withRouter(EditVehicleListing);


