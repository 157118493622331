import React, { Component } from 'react';

import { Navigate } from "react-router-dom";
import { ref, child, get } from "firebase/database";
import { doc, getDoc } from "firebase/firestore";

import Avatar from './Avatar';

const INITIAL_STATE = {
  navigateUrl: null
};

class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE
  }

  backClicked = () => {
    this.setState({ navigateUrl : this.props.back })
  }

  avatarClicked = () => {
    this.setState({ navigateUrl : "/profile" })
  }

  logoClicked = () => {
    this.setState({ navigateUrl : "/switch" })
  }


  render() {
    if (this.state.navigateUrl) {
      var url = this.state.navigateUrl
      return (<Navigate to={url}  replace={true} />)
    }

    var title = this.props.title;
    var items = []

    items.push(
      <div className='headerTitle'>
        <div className='navTitle'>{title}</div>
      </div>
    );

    if (this.props.back)
      items.push(<div className="App-header-back" onClick={this.backClicked} />);
  
    if (!title)
      items.push(<p className='headerIconContainer'><img className='headerIcon' src='/icons/radar-wordmark.png' /></p>);

      /*
    if (this.props.firebase && this.props.firebase.user && !this.props.back) 
      items.push(<img className='App-header-logo' onClick={this.logoClicked} src='/lasoo_icon.png'/>);
*/

    if (this.props.firebase && this.props.firebase.user) 
      items.push(<div className='App-header-avatar' onClick={this.avatarClicked}><Avatar name={this.props.firebase.user.displayName} image={this.props.firebase.user.photoURL} firebase={this.props.firebase.user}/></div>);

    return (
      <div className="App-header">{items}</div>
    );
  }
}

export default AppHeader;