import React, { Component } from 'react';

import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import Button from './Button';
import MessagePopup from './MessagePopup';
import LoadingSpinner from './LoadingSpinner';

import { Navigate, useParams } from "react-router-dom";  

const INITIAL_STATE = {
  validatingUser: false,
  showMessagePopup: false,
  message: "",
  showInvalidCode: false,
};

const withRouter = WrappedComponent => props => {
  const params = useParams();
  // etc... other react-router-dom v6 hooks

  return (
    <WrappedComponent
      {...props}
      params={params}
      // etc...
    />
  );
};

class VerifyEmail extends Component {

  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  } 

  componentDidMount = () => {
    if (this.props.firebase.user && this.props.firebase.user.emailVerified) {
      this.setState( { navigateUrl : '/' })
    }
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateFields = () => {

    if (this.state.code.trim().length !== 4 ) {
      this.showMessagePopup("Please enter a valid 4-digit code");
      return false;
    }

    return true;
  }

  showMessagePopup = (message) => {
    this.setState({
      showMessagePopup: true,
      message: message
    })
  }

  closeMessagePopup = () => {
    this.setState({ showMessagePopup: false })
  }

  continueClicked = () => {

    if (!this.validateFields())
      return;

    this.setState({
      validatingUser : true
    })

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = () => { 
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
             this.setState( { navigateUrl : '/' })
        }
        else if (xmlHttp.readyState === 4 && xmlHttp.status !== 200) {
          this.setState({
            validatingUser : false,
            showInvalidCode: true
          })
        }
    }

    var url = "https://us-central1-radar-e5db0.cloudfunctions.net/validateEmail?email=" + this.props.params.email + "&code=" + this.state.code
    xmlHttp.open("GET", url, true); // true for asynchronous 
    xmlHttp.send(null);
  
  };



  render = () => {   
    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl}  replace={true} />)

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader back="/signup" title="Validate Email" firebase={this.props.firebase} />
          <AppSidebar tab="My Listings" firebase={this.props.firebase}/>
   
          <div className='App-content'>
            <div className='App-content-column-narrow'>
          
                  { this.state.validatingUser ? 
                  <LoadingSpinner/>
                  :
                  <div className='signupFormFields'>
                    <div className='welcomeTitle'><p>Check your email for a code and enter it below.</p></div>

                    { this.state.showInvalidCode ?
                        <div className='welcomeTitle'><p><b>Invalid code, please try again.</b></p></div>
                        :
                        <div/>
                    }

                    <input
                      name="code"
                      value={this.state.code}
                      onChange={this.onChange}
                      type="text"
                      placeholder="4 Digit Code"
                    />


                    <Button type='primary' size='medium' action='Continue' onClick={this.continueClicked}/>

                    </div> 

                  }
                  { this.state.showMessagePopup ? 
                    <MessagePopup message={this.state.message} title="Signup" onClose={this.closeMessagePopup}/>
                    : 
                    <div/>
                  }


            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(VerifyEmail);

