import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Firebase from './Firebase';
import 'firebase/firestore';

import AccessDenied from './AccessDenied'
import Content from './Content'
import EditItemListing from './EditItemListing'
import EditPropertyListing from './EditPropertyListing'
import EditVehicleListing from './EditVehicleListing'
import Home from './Home'
import Login from './Login'
import Profile from './Profile'
import SelectListingType from './SelectListingType'
import Signup from './Signup'
import VerifyEmail from './VerifyEmail'

class AppRouter extends Component {
   constructor(props) {
      super(props);

      this.firebase = new Firebase((user) => {
         this.forceUpdate();
      });

      if (window.mapkit) {
         window.mapkit.init({
            authorizationCallback: function(done) {
              fetch('https://us-central1-radar-e5db0.cloudfunctions.net/mapkitToken')
              .then(response => response.json())
              .then(result => {
                done(result.token)
              })

            }
        });
      }
   }

   render() {
      return (
         <Router>
            <Routes>
               <Route path="/" exact element={<Home firebase={this.firebase}/>}/>
               <Route path="/access-denied" exact element={<AccessDenied firebase={this.firebase}/>}/>
               <Route path="/content" exact element={<Content firebase={this.firebase}/>}/>
               <Route path="/login" exact element={<Login firebase={this.firebase}/>}/>
               <Route path="/signup" exact element={<Signup firebase={this.firebase}/>}/>
               <Route path="/verify-email/:email" exact element={<VerifyEmail firebase={this.firebase}/>}/>

               <Route path="/profile" exact element={<Profile firebase={this.firebase}/>}/>
               <Route path="/select-listing-type" exact element={<SelectListingType firebase={this.firebase}/>}/>
               <Route path="/create-item-listing" exact element={<EditItemListing firebase={this.firebase}/>}/>
               <Route path="/edit-item-listing/:id" exact element={<EditItemListing firebase={this.firebase}/>}/>
               
               <Route path="/create-property-listing" exact element={<EditPropertyListing firebase={this.firebase}/>}/>
               <Route path="/edit-property-listing/:id" exact element={<EditPropertyListing firebase={this.firebase}/>}/>

               <Route path="/create-vehicle-listing" exact element={<EditVehicleListing firebase={this.firebase}/>}/>
               <Route path="/edit-vehicle-listing/:id" exact element={<EditVehicleListing firebase={this.firebase}/>}/>
            </Routes>    
         </Router>
      );
   }
}

export default AppRouter;