import React, { Component } from 'react';

const INITIAL_STATE = {

};

/*
 * Props 
 *   type = 'primary'|'secondary'
 *   size = 'medium'|'small'
 *   onClick = function
 */
class PhotosSummary extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  } 

  getLabel = () => {
    return "+ " + (this.props.photos.length-1) + " " + ((this.props.photos.length-1 === 1) ? "image" : "images")
  }

  renderPhotos = () => {
    return <div>
      <img className="photosSummaryImage" src={this.props.photos[0]}/>
      <div className="photosSummaryLabel">{this.getLabel()}</div>
    </div>
  }


  render = () => {
    return ( 
      <div className='photosSummary' onClick={this.props.onClick}>
        { !this.props.photos || this.props.photos.length === 0 
          ?
          <div className='photosSummaryText'>⇑ Upload photos</div>
          :
          this.renderPhotos()
        }
      </div>
    )
  }
}

export default PhotosSummary;