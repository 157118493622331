const config = {
    apiKey: "AIzaSyDGof6iQpXpjfaBFIpUnA8zXcooilKtbp8",
    authDomain: "radar-e5db0.firebaseapp.com",
    databaseURL: "https://radar-e5db0-default-rtdb.firebaseio.com",
    projectId: "radar-e5db0",
    storageBucket: "radar-e5db0.appspot.com",
    messagingSenderId: "1042880845278",
    appId: "1:1042880845278:web:6682cd7a998664b013b94f",
    measurementId: "G-5NYWJKWJY0"
};

export default config;

