import React, { Component } from 'react';

import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import Button from './Button';
import MessagePopup from './MessagePopup';
import LoadingSpinner from './LoadingSpinner';
import { Navigate } from "react-router-dom";

import emailValidator from "email-validator";
import passwordValidator from "password-validator";


const INITIAL_STATE = {
  name: '',
  email: '',
  password: '',
  error: null,
  creatingUser: false,
  showMessagePopup: false,
  message: ""
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them.
`;  

class Signup extends Component {

  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  } 

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getPasswordValidator = () => {
    var schema = new passwordValidator();

    schema
    .is().min(8)                                    // Minimum length 8
    .is().max(100)                                  // Maximum length 100
    .has().uppercase()                              // Must have uppercase letters
    .has().lowercase()                              // Must have lowercase letters
    .has().digits(1)                                // Must have at least 2 digits
    .has().not().spaces()   

    return schema
  }

  validateFields = () => {

    if (!emailValidator.validate(this.state.email)) {
      this.showMessagePopup("Please enter a valid email address");
      return false;
    }

    if (this.state.name.trim().length < 3 || this.state.name.indexOf("@") >= 0 || this.state.name.indexOf(" ") < 0) {
      this.showMessagePopup("Please enter a valid full name");
      return false;
    }

    if (!this.getPasswordValidator().validate(this.state.password)) {
      this.showMessagePopup("Passwords must be alteast 8 characters, with an uppercase, a lowercase and a digit");
      return false;
    }

    if (this.state.password !== this.state.confirmPassword) {
      this.showMessagePopup("Confirmation password does not match");
      return false;
    }
    
    return true;
  }

  showMessagePopup = (message) => {
    this.setState({
      showMessagePopup: true,
      message: message
    })
  }

  closeMessagePopup = () => {
    this.setState({ showMessagePopup: false })
  }

  continueClicked = () => {

    if (!this.validateFields())
      return;

    this.setState({
      creatingUser : true
    })
 
    this.props.firebase
      .doCreateUserWithEmailAndPassword(this.state.name, this.state.email, this.state.password, (user) => {

            user.sendEmailVerification()
            .then(() => {
                user.reload().then(() => {
                  this.setState({ navigateUrl: "/verify-email/" + this.state.email })
                })
            })
            .catch((error) => {
              // Handle Errors here.
                alert("Got error sending verification email: " + error)
            });
        })

        /*
      .catch(error => {
        this.setState({ creatingUser: false, error });

        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) 
          this.showMessagePopup("An account with that email already exists")
        else
          this.showMessagePopup(error.message)
      }); */
  };



  render = () => {
    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl}  replace={true} />)

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader back="/" title="Sign Up" firebase={this.props.firebase} />
          <AppSidebar tab="My Listings" firebase={this.props.firebase}/>
   
          <div className='App-content'>
            <div className='App-content-column-narrow'>

                  { this.state.creatingUser ? 
                  <LoadingSpinner/>
                  :
                  <div className='signupFormFields'>
                    <input
                      name="email"
                      value={this.state.email}
                      onChange={this.onChange}
                      type="text"
                      placeholder="Email address"
                    />

                    <input
                      name="name"
                      value={this.state.name}
                      onChange={this.onChange}
                      type="text"
                      placeholder="Full Name"
                    />


                    <input
                      name="password"
                      value={this.state.password}
                      onChange={this.onChange}
                      type="password"
                      placeholder="Password"
                    />

                    <input
                      name="confirmPassword"
                      value={this.state.confirmPassword}
                      onChange={this.onChange}
                      type="password"
                      placeholder="Confirm password"
                    />

                    <Button type='primary' size='medium' action='Continue' onClick={this.continueClicked}/>

                    </div> 

                  }
                  { this.state.showMessagePopup ? 
                    <MessagePopup message={this.state.message} title="Signup" onClose={this.closeMessagePopup}/>
                    : 
                    <div/>
                  }


            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;

