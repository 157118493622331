import React, { Component } from 'react';
import { doc, getDoc, setDoc } from "firebase/firestore"; 
import { Navigate, useParams } from "react-router-dom";

import short from 'short-uuid';

import AddressSelector from './AddressSelector';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import Button from './Button';
import ImageUploader from './ImageUploader';
import LoadingSpinner from './LoadingSpinner';
import MessagePopup from './MessagePopup';
import PhotosSummary from './PhotosSummary';
import TextareaAutosize  from 'react-textarea-autosize';
import WelcomeForm from './WelcomeForm'

const withRouter = WrappedComponent => props => {
  const params = useParams();
  // etc... other react-router-dom v6 hooks

  return (
    <WrappedComponent
      {...props}
      params={params}
      // etc...
    />
  );
};


const INITIAL_STATE = {
  createItem: true,
  dataLoaded: false,
  loadingListing: false,

  id : short().new(),
  title : "",
  price : 0,
  description : "",
  images : [],
  
  suburb : null,
  state : null,
  latitude : null,
  longitude : null,

  showAddressSelector: false,
  showImageUploader: false,

  showPopupMessage: false,
  popupTitle: "",
  popupMessage: "",
  popupOnClose: null
};

class EditItemListing extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

  }

  componentDidMount = () => {
    if (this.props.params && this.props.params.id) {
      this.setState({ createItem: false, id: this.props.params.id })
      this.loadListing(this.props.params.id)
    }
  }

  loadListing = (id) => {
    this.setState({ loadingListing: true })

    getDoc(doc(this.props.firebase.firestore, "items", id)).then((doc) => {
      if (doc.exists) {
        var item = doc.data()

        this.setState({ 
          dataLoaded: true, 
          loadingListing: false, 
          title : item.title,
          price : item.price.toString(),
          description : item.description,
          images : item.images,
          suburb : item.suburb,
          state : item.state,
          latitude : item.latitude,
          longitude : item.longitude,
        })
      }
      else {
        alert("Could not find listing")
        this.redirectToHome()
      }
    })
  }

  renderSuburbSelector = () => {
    return <div>
      <div className='formFieldName'>Location</div>
      <div onClick={ () => { this.showAddressSelector() } } className='suburbSelectorBox'>{this.getSuburbText()}</div>
    </div>
  }

  showAddressSelector = () => {
    this.setState({ showAddressSelector: true })
  }

  closeAddressSelector = () => {
    this.setState({ showAddressSelector: false })
  }

  getSuburbText = () => {
    if (!this.state.suburb || !this.state.state || !this.state.latitude || !this.state.longitude) {
        return "🔍 Select your suburb"
    }
    else {
        return this.state.suburb + ", " + this.state.state
    }
  }

  addImage = (imageUrl) => {
    let images = [...this.state.images]
    images.push(imageUrl)

    this.setState({ images: images })
  }

  deleteImage = (index) => {
    if (index < this.state.images.length) {
      let images = this.state.images
      images.splice(index, 1)
      this.setState({ images: images })
    }
  }

  showPopupMessage = (title, message, onClose) => {
    this.setState({ showPopupMessage: true, popupTitle: title, popupMessage: message, popupOnClose: onClose })
  }

  closePopupMessage = () => {
    this.setState({ showPopupMessage: false })
  }

  showImageUploader = () => {
    this.setState({ showImageUploader: true })
  }

  closeImageUploader = () => {
    this.setState({ showImageUploader: false })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addressSelected = (address, suburb, state, latitude, longitude) => {
    this.setState({ suburb: suburb, state: state,  latitude: latitude, longitude: longitude, showAddressSelector: false })
  }

  isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  validateListing = () => {
    if (!this.state.suburb || !this.state.state || !this.state.latitude || !this.state.longitude) {
      this.showPopupMessage("Listing validation", "Please select item location", this.closePopupMessage)
      return false
    }
    
    if (this.state.title === "" && this.state.title.length < 10) {
        this.showPopupMessage("Listing validation", "Please enter a title", this.closePopupMessage)
        return false
    }
    
    if (!this.isNumeric(this.state.price) || parseInt(this.state.price) <= 0) {
        this.showPopupMessage("Listing validation", "Please enter a valid price", this.closePopupMessage)
        return false
    }
    
    if (this.state.images.length === 0) {
        this.showPopupMessage("Listing validation", "Please upload some photos", this.closePopupMessage)
        return false
    }
    
    if (this.state.description.length < 20) {
        this.showPopupMessage("Listing validation", "Please enter a detailed description", this.closePopupMessage)
        return false
    }

    return true
  }

  redirectToHome = () => {
    this.setState({ navigateUrl: "/"})
  }

  storeListing = (status, callback) => {
    setDoc(doc(this.props.firebase.firestore, "items", this.state.id), {
      description: this.state.description,
      images: this.state.images,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      price: parseInt(this.state.price),
      state: this.state.state,
      status: status,
      suburb: this.state.suburb,
      title: this.state.title,
      vendorId: this.props.firebase.auth.currentUser.uid,
      vendorName: this.props.firebase.auth.currentUser.displayName
    })
    .then(callback)
  }

  postListingClicked = () => {
    if (!this.validateListing())
      return

    this.storeListing("Active", () => {
        if (this.state.createItem) 
           this.showPopupMessage("Item listing", "Your listing has been posted successfully", this.redirectToHome)
        else 
           this.showPopupMessage("Item listing", "Your listing has been updated successfully", this.redirectToHome)
    })
  }

  endListingClicked = () => {
    if (!this.validateListing())
      return

    this.storeListing("Ended", () => {
         this.showPopupMessage("Item listing posted", "Your listing has been ended", this.redirectToHome)
    })
  }

  renderCreateItemListingForm = () => {

    if (this.state.loadingListing) {
      return <LoadingSpinner/>
    }

    return <div>
        {this.renderSuburbSelector()} 

        <div className='formFieldName'>Title</div>
        <div className='formFieldInput'>      
          <input name="title" value={this.state.title} onChange={this.onChange} type="text" placeholder="Title"/>
        </div>

        <div className='formFieldName'>Price ($)</div>
        <div className='formFieldInput'>      
          <input name="price" value={this.state.price} onChange={this.onChange} type="text" placeholder="$0"/>
        </div>

        <div className='formFieldName'>Photos</div>
        <div className='formFieldInput'>      
          <PhotosSummary photos={this.state.images} onClick={() => this.showImageUploader()} />
        </div>

        <div className='formFieldName'>Description</div>
        <div className='formFieldInput'>      
          <TextareaAutosize minRows="6" cols="60" name='description' onChange={this.onChange}  value={this.state.description}/>
        </div>

        <br/>
        <Button size='medium' type='primary' action={(this.state.createItem) ? 'Post listing' : 'Update listing'} onClick={ this.postListingClicked }/>
        { !this.state.createItem ?
          <div>
             <br/>
              <Button size='medium' type='secondary' action={'End listing'} onClick={ this.endListingClicked }/>
        </div>
        :
        <div/>
        }
      </div>
  }


  render = () => {
    var firebase = this.props.firebase;
    var userLoaded = firebase.initialised;
    var user = this.props.firebase.user

    var title = (this.state.createItem) ? "Create Item Listing" : "Update Item Listing"
    var back = (this.state.createItem) ? "/select-listing-type" : "/"

    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl}  replace={true} />)

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader back={back} title={title} firebase={firebase} />
          <AppSidebar tab="Dashboard" firebase={firebase}/>
          <div className='App-content'>
            <div className='App-content-column-narrow'>
            {
              !userLoaded 
                ?
                <div/>
                :
              user
                ? 
                this.renderCreateItemListingForm()
                : 
                <WelcomeForm/>
            }
           </div>
          </div>

            { this.state.showAddressSelector ?
                  <AddressSelector addressType='suburb' addressSelected={this.addressSelected} onClose={ () => this.closeAddressSelector() } />
                  :
                  <div/>
            }

           { this.state.showImageUploader ? 
              <ImageUploader firebase={this.props.firebase} images={this.state.images} imageAdded={this.addImage} imageDelete={this.deleteImage} onClose={() => this.closeImageUploader()}/>
              :
              <div/>
            }

            { this.state.showPopupMessage ?
              <MessagePopup title={this.state.popupTitle} message={this.state.popupMessage} onClose={this.state.popupOnClose}/>
              :
              <div/>
            }
          <AppFooter tab="My Listings"/>
        </div>
      </div>
    )
  }
}

export default withRouter(EditItemListing);

