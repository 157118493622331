import React, { Component } from 'react';
import { doc, getDoc, setDoc } from "firebase/firestore"; 
import { Navigate, useParams } from "react-router-dom";

import Select from "react-select";

import short from 'short-uuid';

import AddressSelector from './AddressSelector';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import Button from './Button';
import ImageUploader from './ImageUploader';
import LoadingSpinner from './LoadingSpinner';
import MessagePopup from './MessagePopup';
import PhotosSummary from './PhotosSummary';
import TextareaAutosize  from 'react-textarea-autosize';
import WelcomeForm from './WelcomeForm'

const withRouter = WrappedComponent => props => {
  const params = useParams();
  // etc... other react-router-dom v6 hooks

  return (
    <WrappedComponent
      {...props}
      params={params}
      // etc...
    />
  );
};


const INITIAL_STATE = {
  createProperty: true,
  dataLoaded: false,
  loadingListing: false,

  listingType : "ForSale",
  propertyType : "House",

  id : short().new(),
  price : 0,
  description : "",
  images : [],

  bedrooms: "0",
  bathrooms: "0",
  cars: "0",
  
  address : null,
  suburb : null,
  state : null,
  latitude : null,
  longitude : null,

  showAddressSelector: false,
  showImageUploader: false,

  showPopupMessage: false,
  popupTitle: "",
  popupMessage: "",
  popupOnClose: null
};

class EditPropertyListing extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

  }

  componentDidMount = () => {
    if (this.props.params && this.props.params.id) {
      this.setState({ createProperty: false, id: this.props.params.id })
      this.loadListing(this.props.params.id)
    }
  }

  loadListing = (id) => {
    this.setState({ loadingListing: true })

    getDoc(doc(this.props.firebase.firestore, "properties", id)).then((doc) => {
      if (doc.exists) {
        var property = doc.data()

        this.setState({ 
          dataLoaded: true, 
          loadingListing: false, 
          listingType: property.listingType,
          propertyType: property.type,
          bedrooms: property.bedrooms.toString(),
          bathrooms: property.bathrooms.toString(),
          cars: property.cars.toString(),
          price : property.price.toString(),
          description : property.description,
          images : property.images,
          address: property.address,
          suburb : property.suburb,
          state : property.state,
          latitude : property.latitude,
          longitude : property.longitude,
        })
      }
      else {
        alert("Could not find listing")
        this.redirectToHome()
      }
    })
  }

  renderAddressSelector = () => {
    return <div>
      <div className='formFieldName'>Location</div>
      <div onClick={ () => { this.showAddressSelector() } } className='suburbSelectorBox'>{this.getAddressText()}</div>
    </div>
  }

  showAddressSelector = () => {
    this.setState({ showAddressSelector: true })
  }

  closeAddressSelector = () => {
    this.setState({ showAddressSelector: false })
  }

  getAddressText = () => {
    if (!this.state.address || !this.state.suburb || !this.state.state || !this.state.latitude || !this.state.longitude) {
        return "🔍 Select property address"
    }
    else {
        return this.state.address + ", " + this.state.suburb + ", " + this.state.state
    }
  }

  addImage = (imageUrl) => {
    let images = [...this.state.images]
    images.push(imageUrl)

    this.setState({ images: images })
  }

  deleteImage = (index) => {
    if (index < this.state.images.length) {
      let images = this.state.images
      images.splice(index, 1)
      this.setState({ images: images })
    }
  }

  showPopupMessage = (title, message, onClose) => {
    this.setState({ showPopupMessage: true, popupTitle: title, popupMessage: message, popupOnClose: onClose })
  }

  closePopupMessage = () => {
    this.setState({ showPopupMessage: false })
  }

  showImageUploader = () => {
    this.setState({ showImageUploader: true })
  }

  closeImageUploader = () => {
    this.setState({ showImageUploader: false })
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addressSelected = (address, suburb, state, latitude, longitude) => {
    this.setState({ address: address, suburb: suburb, state: state,  latitude: latitude, longitude: longitude, showAddressSelector: false })
  }

  isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  validateListing = () => {
    if (!this.state.suburb || !this.state.state || !this.state.latitude || !this.state.longitude) {
      this.showPopupMessage("Listing validation", "Please select property address", this.closePopupMessage)
      return false
    }

    if (!this.isNumeric(this.state.bedrooms) || parseInt(this.state.bedrooms) <= 0) {
      this.showPopupMessage("Listing validation", "Please enter a valid number of bedrooms", this.closePopupMessage)
      return false
    }
  
    if (!this.isNumeric(this.state.bathrooms) || parseInt(this.state.bathrooms) <= 0) {
      this.showPopupMessage("Listing validation", "Please enter a valid number of bathrooms", this.closePopupMessage)
      return false
    }

    if (!this.isNumeric(this.state.cars) || parseInt(this.state.cars) < 0) {
      this.showPopupMessage("Listing validation", "Please enter a valid number of car spaces", this.closePopupMessage)
      return false
    }

    if (!this.isNumeric(this.state.price) || parseInt(this.state.price) <= 0) {
        this.showPopupMessage("Listing validation", "Please enter a valid price", this.closePopupMessage)
        return false
    }
    
    if (this.state.images.length === 0) {
        this.showPopupMessage("Listing validation", "Please upload some photos", this.closePopupMessage)
        return false
    }
    
    if (this.state.description.length < 20) {
        this.showPopupMessage("Listing validation", "Please enter a detailed description", this.closePopupMessage)
        return false
    }

    return true
  }

  redirectToHome = () => {
    this.setState({ navigateUrl: "/"})
  }

  storeListing = (status, callback) => {
    setDoc(doc(this.props.firebase.firestore, "properties", this.state.id), {
      listingType: this.state.listingType,
      type: this.state.propertyType,
      bedrooms: parseInt(this.state.bedrooms),
      bathrooms: parseInt(this.state.bathrooms),
      cars: parseInt(this.state.cars),
      description: this.state.description,
      images: this.state.images,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      price: parseInt(this.state.price),
      status: status,
      address: this.state.address,
      suburb: this.state.suburb,
      state: this.state.state,
      vendorId: this.props.firebase.auth.currentUser.uid,
      vendorName: this.props.firebase.auth.currentUser.displayName
    })
    .then(callback)
  }

  postListingClicked = () => {
    if (!this.validateListing())
      return

    this.storeListing("Active", () => {
        if (this.state.createProperty) 
           this.showPopupMessage("Property listing", "Your listing has been posted successfully", this.redirectToHome)
        else 
           this.showPopupMessage("Property listing", "Your listing has been updated successfully", this.redirectToHome)
    })
  }

  endListingClicked = () => {
    if (!this.validateListing())
      return

    this.storeListing("Ended", () => {
         this.showPopupMessage("Property listing posted", "Your listing has been ended", this.redirectToHome)
    })
  }

  listTypeChanged = (option) => {
    this.setState({ listingType: option.value })
  }

  renderListingTypeSelector = () => {
    var options = [
      { value: "ForSale", label: "For Sale" },
      { value: "ForRent", label: "For Rent" }
    ]
    var defaultValue = options.filter(option => option.value === this.state.listingType)
    return <Select name="listingType" defaultValue={defaultValue} options={options} onChange = {this.listTypeChanged}/>
  }

  propertyTypeChanged = (option) => {
    this.setState({ propertyType: option.value })
  }

  renderPropertyTypeSelector = () => {
    var options = [
      { value: "House", label: "House" },
      { value: "Apartment", label: "Apartment" }
    ]
    var defaultValue = options.filter(option => option.value === this.state.propertyType)
    return <Select name="propertyType" defaultValue={defaultValue} options={options} onChange = {this.propertyTypeChanged}/>
  }

  renderCreatePropertyListingForm = () => {

    if (this.state.loadingListing) {
      return <LoadingSpinner/>
    }

    return <div>
        <div className='formFieldName'>Listing Type</div>
        <div className='formFieldInput'>{this.renderListingTypeSelector()}</div>

        {this.renderAddressSelector()} 

        <div className='formFieldName'>Property Type</div>
        <div className='formFieldInput'>{this.renderPropertyTypeSelector()}</div>

        <div className='formFieldName'>Bedrooms</div>
        <div className='formFieldInput'>      
          <input name="bedrooms" value={this.state.bedrooms} onChange={this.onChange} type="text" placeholder="0"/>
        </div>

        <div className='formFieldName'>Bathrooms</div>
        <div className='formFieldInput'>      
          <input name="bathrooms" value={this.state.bathrooms} onChange={this.onChange} type="text" placeholder="0"/>
        </div>

        <div className='formFieldName'>Car Spaces</div>
        <div className='formFieldInput'>      
          <input name="cars" value={this.state.cars} onChange={this.onChange} type="text" placeholder="0"/>
        </div>

        <div className='formFieldName'>Price ($)</div>
        <div className='formFieldInput'>      
          <input name="price" value={this.state.price} onChange={this.onChange} type="text" placeholder="$0"/>
        </div>

        <div className='formFieldName'>Photos</div>
        <div className='formFieldInput'>      
          <PhotosSummary photos={this.state.images} onClick={() => this.showImageUploader()} />
        </div>

        <div className='formFieldName'>Description</div>
        <div className='formFieldInput'>      
          <TextareaAutosize minRows="6" cols="60" name='description' onChange={this.onChange}  value={this.state.description}/>
        </div>

        <br/>
        <Button size='medium' type='primary' action={(this.state.createProperty) ? 'Post listing' : 'Update listing'} onClick={ this.postListingClicked }/>
        { !this.state.createProperty ?
          <div>
             <br/>
              <Button size='medium' type='secondary' action={'End listing'} onClick={ this.endListingClicked }/>
            <br/>
        </div>
        :
        <div/>
        }
      </div>
  }


  render = () => {
    var firebase = this.props.firebase;
    var userLoaded = firebase.initialised;
    var user = this.props.firebase.user

    var title = (this.state.createProperty) ? "Create Property Listing" : "Update Property Listing"
    var back = (this.state.createProperty) ? "/select-listing-type" : "/"

    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl}  replace={true} />)

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader back={back} title={title} firebase={firebase} />
          <AppSidebar tab="Dashboard" firebase={firebase}/>
          <div className='App-content'>
            <div className='App-content-column-narrow'>
            {
              !userLoaded 
                ?
                <div/>
                :
              user
                ? 
                this.renderCreatePropertyListingForm()
                : 
                <WelcomeForm/>
            }
           </div>
          </div>

            { this.state.showAddressSelector ?
                  <AddressSelector addressType='address' addressSelected={this.addressSelected} onClose={ () => this.closeAddressSelector() } />
                  :
                  <div/>
            }

           { this.state.showImageUploader ? 
              <ImageUploader firebase={this.props.firebase} images={this.state.images} imageAdded={this.addImage} imageDelete={this.deleteImage} onClose={() => this.closeImageUploader()}/>
              :
              <div/>
            }

            { this.state.showPopupMessage ?
              <MessagePopup title={this.state.popupTitle} message={this.state.popupMessage} onClose={this.state.popupOnClose}/>
              :
              <div/>
            }
          <AppFooter tab="My Listings"/>
        </div>
      </div>
    )
  }
}

export default withRouter(EditPropertyListing);


