import Button from './Button'

import React, { Component } from 'react';
import { Navigate } from "react-router-dom";

const INITIAL_STATE = {
  navigateUrl : null
};

class WelcomeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  login = () => {
    this.setState({ "navigateUrl": "/login" })
  }

  signup = () => {
    this.setState({ "navigateUrl": "/signup" })
  }

  render = () => {
    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl} replace={true} />)

    return ( 
      <form>
        <p><Button type='primary' size='medium' onClick={() => this.signup()} action='Sign Up'/></p>
        <p><Button type='secondary' size='medium' onClick={() => this.login()} action='Login'/></p>
     </form>
    )
  }
}

export default WelcomeForm;