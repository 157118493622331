import React, { Component } from 'react';

import { getDownloadURL, ref, uploadString } from "firebase/storage";

import Button from './Button'
import LoadingSpinner from './LoadingSpinner';
import Popup from 'reactjs-popup';
import PopupHeader from './PopupHeader'
import Resizer from "react-image-file-resizer";

import short from 'short-uuid';

import 'reactjs-popup/dist/index.css';

var INITIAL_STATE = {
  selectedFileName : null,
  selectedFile : null,
  validImage : false,
  uploadingImage : false
}

class ImageUploader extends Component {

  constructor(props) {
      super(props);
      this.state = INITIAL_STATE;
  } 

  componentWillMount = () => {

  }

  imageDelete = (index) => {
    if (this.props.imageDelete) {
      this.props.imageDelete(index);
    }
  }

  fileChanged = (file) => {  

    // validate image orientation

    var image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () =>  {
      var width = image.naturalWidth,
          height = image.naturalHeight;
      URL.revokeObjectURL(image.src);

      if (width <= height) {
        this.setState({ validImage: false, selectedFile: file })
        alert("Please select a landscape image");
      }
      else {
        this.setState({ validImage: true, selectedFile: file })
      }
    }

  }

  addImageClicked = () => {
    if (!this.state.validImage) {
      alert("Please select a valid image");
      return;
    }

    this.setState({ uploadingImage: true})
    // Resize image

    Resizer.imageFileResizer(
      this.state.selectedFile,
      600,
      400,
      "JPEG",
      100,
      0,
      (uri) => {
        const id = short().new();
        const storage = this.props.firebase.storage;
        const sref = ref(storage, "listings/items/" + id + ".jpg");

        uploadString(sref, uri, 'data_url').then((snapshot) => {

          getDownloadURL(sref).then((url) => {
              this.setState({ uploadingImage: false })    

              if (this.props.imageAdded) {
                  this.props.imageAdded(url)
              }
          })
        })
      },
      "base64",
      300,
      200
    );


  }


  renderImages = () => {
    var items = []
    if (this.props.images)
       this.props.images.forEach((image, index) => {
          items.push(<div className="imageUploaderImageContainer">
            <img key={index} src={image} className="imageUploaderImage"/>
            <img src="/icons/delete.png" onClick={this.imageDelete.bind(this, index)} className="imageUploaderImageDelete"/>
          </div>)
       });

    return <div className='imageUploaderImageList'>
                {items}

                <table className='imageUploadButtonTable'>
                  <tr>
                    <td width='50%'>              
                     <input type="file" accept="image/*" onChange={(e) => this.fileChanged(e.target.files[0])}
                  /> </td>
                  <td  width='50%'><Button type='primary' size='medium' onClick={this.addImageClicked} action="Add Image"/> </td>
                  </tr>
                
                </table>
                <br/>     
          </div>;
  }

  render = () => {
    return (
        <Popup open="true" closeOnDocumentClick className='image-uploader-popup' onClose={this.props.onClose}>
          <PopupHeader title="Image Uploader"/>
          { this.state.uploadingImage
          ? <div className='imageUploaderSpinnerContainer'>
              <LoadingSpinner/>
            </div>
          :
           this.renderImages()
          } 

        </Popup>
    );
  }
};

export default ImageUploader;
