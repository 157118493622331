import React, { Component } from 'react';

import Popup from 'reactjs-popup';
import PopupHeader from './PopupHeader'

import 'reactjs-popup/dist/index.css';

const INITIAL_STATE = {
  address: "",
  completions : []
}

class AddressSelector extends Component {

  constructor(props) {
      super(props);
      this.state = INITIAL_STATE;

      const region = new window.mapkit.CoordinateRegion(
          new window.mapkit.Coordinate(-24.6777, 134.15),
          new window.mapkit.CoordinateSpan(2, 2)
      )

      this.search = new window.mapkit.Search({ limitToCountries: "AU", region: region });
  }

  componentWillMount = () => {

  }

  addressChanged = (event) => {
    this.setState({address: event.target.value});
    this.searchForString(event.target.value);
  }

  isDigit = (ch) => {
    return ch >= '0' && ch <= '9'
  }

  filterPlaces = (places) => {
    var filteredPlaces = [];

    if (places) {
      places.forEach((place) => {
        if (place.formattedAddress && this.props.addressType === 'address' && this.isDigit(place.formattedAddress[0]))
          filteredPlaces.push(place);

        if (place.formattedAddress && this.props.addressType === 'suburb' && !this.isDigit(place.formattedAddress[0]))
          filteredPlaces.push(place);   
      })
    }

    return filteredPlaces
  }

  searchForString = (searchString) => {

    this.search.search(searchString, (error, data) => {
        if (error) {
            return;
        }

        this.setState({ completions: this.filterPlaces(data.places) })
    },
    {
      includePointsOfInterest: false,
      includeAddresses: true
    });
  }

  completionClicked = (index) => {
    if (index < this.state.completions.length) {
       const completion = this.state.completions[index];

       if (completion.administrativeAreaCode && completion.locality && completion.region && completion.region.center && completion.region.center.latitude) {
          if (this.props.addressSelected) {
            var address = (this.props.addressType === 'address') ? completion.name : ""
            this.props.addressSelected(address, completion.locality, completion.administrativeAreaCode, completion.region.center.latitude.toString(), completion.region.center.longitude.toString())
          }
       }
    }
  }

  renderCompletions = () => {
    var items = []
    var index = 0;

    this.state.completions.forEach((completion) => {
      items.push(<div onClick={this.completionClicked.bind(this, index)} className='addressCompletion'>
        {(this.props.addressType === 'suburb' ? completion.name : completion.formattedAddress.replace(", Australia", ""))}
      </div>) 
      index += 1
    })

    return items;
  }

  capitalise = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getTitle = () => {
    return "Select " + this.capitalise(this.props.addressType)
  }

  render = () => {
    return (
        <Popup open="true" closeOnDocumentClick className='address-selector-popup' onClose={this.props.onClose}>
          <PopupHeader title={this.getTitle()}/>
          <br/>
          <div className='formFieldInput'>      
          <input name="address" value={this.state.address} onChange={this.addressChanged} type="text" placeholder={this.capitalise(this.props.addressType)}/>
          { this.renderCompletions() }
        </div>
        </Popup>
    );
  }
};

export default AddressSelector;
