import React, { Component } from 'react';

import { collection, where, query, getDocs } from "firebase/firestore";
import { Navigate } from "react-router-dom";

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import Button from './Button';
import LoadingSpinner from './LoadingSpinner';
import WelcomeForm from './WelcomeForm';

const INITIAL_STATE = {
  dataLoaded: false,

  itemListings : [],
  propertyListings : [],
  vehicleListings : []
};

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {
      this.fetchItemListings()
      this.fetchPropertyListings()
      this.fetchVehicleListings()
    }
  }

  fetchItemListings = () => {
    var items = []

    const q = query(collection(this.props.firebase.firestore, "items"), where("vendorId", "==", this.props.firebase.user.uid, "status", "==", "Active"));

    getDocs(q).then((snapshot) => {
        snapshot.forEach((doc) => {
          var item = doc.data()
          if (item.status === 'Active') {
            item.id = doc.id
            items.push(item)
          }
        });

        this.setState({ dataLoaded : true, itemListings: items })
      })
      .catch((error) => {
        alert("Error getting items: " + error)
      })
  }

  fetchPropertyListings = () => {
    var properties = []

    const q = query(collection(this.props.firebase.firestore, "properties"), where("vendorId", "==", this.props.firebase.user.uid));

    getDocs(q).then((snapshot) => {
        snapshot.forEach((doc) => {
          var property = doc.data()
          if (property.status === 'Active') {
            property.id = doc.id
            properties.push(property)
          }
        });
        this.setState({ dataLoaded : true, propertyListings: properties })
      })
      .catch((error) => {
        console.error("Error getting items: " + error)
      })
  }

  fetchVehicleListings = () => {
    var vehicles = []

    const q = query(collection(this.props.firebase.firestore, "vehicles"), where("vendorId", "==", this.props.firebase.user.uid));

    getDocs(q).then((snapshot) => {
        snapshot.forEach((doc) => {
          var vehicle = doc.data()
          if (vehicle.status === 'Active') {
             vehicle.id = doc.id
             vehicles.push(vehicle)
          }
        });

        this.setState({ dataLoaded : true, vehicleListings: vehicles })
      })
      .catch((error) => {
        console.error("Error getting items: " + error)
      })
  }  

  createListingClicked = () => {
    this.setState({ navigateUrl : '/select-listing-type' })
  }

  renderZeroStateScreen = () => {
    return <div>
      <img className='sellingImage' src="/selling.jpeg"/>
      <div className='sellingMessage'>With a Radar subscription you can list<br/> unlimited items.</div>
      <Button size='medium' type='primary' action='Create a listing' onClick={ this.createListingClicked }/>
    </div>;
  }

  itemListingClicked = (id) => {
    this.setState({ navigateUrl : '/edit-item-listing/' + id })
  }

  propertyListingClicked = (id) => {
    this.setState({ navigateUrl : '/edit-property-listing/' + id })
  }

  vehicleListingClicked = (id) => {
    this.setState({ navigateUrl : '/edit-vehicle-listing/' + id })
  }

  renderMyListings = () => {
    if (this.state.itemListings.length === 0 && this.state.propertyListings.length === 0 && this.state.vehicleListings.length === 0)
      return this.renderZeroStateScreen();
    else {
      var listings = []

      this.state.itemListings.forEach((item) => {
        listings.push(<div onClick={this.itemListingClicked.bind(this, item.id)} className='listingSummary'>
          <img className='listingSummaryImage' src={item.images[0]}/>
          <div className='listingSummaryTitle'>{item.title}</div>
          <div className='listingSummarySubtitle'>{item.suburb}</div>
        </div>)
      })

      this.state.propertyListings.forEach((property) => {
        listings.push(<div onClick={this.propertyListingClicked.bind(this, property.id)} className='listingSummary'>
          <img className='listingSummaryImage' src={property.images[0]}/>
          <div className='listingSummaryTitle'>{property.address}</div>
          <div className='listingSummarySubtitle'>{property.suburb}</div>
        </div>)
      })

      this.state.vehicleListings.forEach((vehicle) => {
        listings.push(<div onClick={this.vehicleListingClicked.bind(this, vehicle.id)}  className='listingSummary'>
          <img className='listingSummaryImage' src={vehicle.images[0]}/>
          <div className='listingSummaryTitle'>{vehicle.make + " " + vehicle.model}</div>
          <div className='listingSummarySubtitle'>{vehicle.suburb}</div>
        </div>)
      })

      return <div>
        {listings}
        <br/>
        <Button size='medium' type='primary' action='Create a listing' onClick={ this.createListingClicked }/>
      </div>;
    }
  }

  render = () => {
    var firebase = this.props.firebase;
    var userLoaded = firebase.initialised;
    var user = this.props.firebase.user

    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl}  replace={true} />)

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader firebase={firebase}/>
          <AppSidebar tab="Reports" firebase={firebase}/>
          <div className='App-content'>
            <div className='App-content-column'>
            {
              !userLoaded 
                ?
                <div>
                  
                </div>
                :  
              user
                ? 
                !this.state.dataLoaded 
                ?
                <LoadingSpinner/>
                :
                <div>
                    { this.renderMyListings() }
                   </div>
                : 
                <WelcomeForm/>
            }
           </div>
          </div>

          <AppFooter tab="My Listings"/>
        </div>
      </div>
    )
  }
}

export default Home; 

