import React, { Component } from 'react';

import { Navigate } from "react-router-dom";

const tabs = ['My Listings' ] // 'Content', 'Settings'];
const tabHomes = { 'My Listings' : '/' } //  '/content', 'Settings' : '/settings' }

const INITIAL_STATE = {
  navigateUrl: null,
};

class TabBar extends Component {

    constructor(props) {
      super(props);
      this.state = INITIAL_STATE
   }

    selectTab(tab) {
      if (tab !== this.props.tab)
        this.setState({ "navigateUrl": tabHomes[tab] })
    }
    
    render() {
      if (this.state.navigateUrl) {
        var url = this.state.navigateUrl
        return (<Navigate to={url}  replace={true} />)
      }

      var selectedTab = this.props.tab;
      const items = []

      for (const [i, tab] of tabs.entries()) {
        var icon = (tab === selectedTab) ? "/icons/" + tab.toLowerCase().replace(" ", "-") + "-icon.png" : "/icons/" + tab.toLowerCase().replace(" ", "-") + "-icon-disabled.png";
        var labelClass = (tab === selectedTab) ? "tabBarLabel" : "tabBarLabelDisabled"

        items.push(         
          <div key={tab} className="tabBarItem" onClick={() => this.selectTab(tab)}>  
            <div className="tabBarIconContainer">
                <img className="tabBarIcon" src={icon}/>
            </div>
            <div className="tabBarLabelContainer">
              <span className={labelClass}>{tab}</span>
            </div>        
          </div>);
      }

      return (
        <div className="tabBarContainer">
          { items }
        </div>
      );
    }
}

export default TabBar;